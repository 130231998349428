@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css?family=Raleway:300,400,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');


body {
    font-family: 'Raleway', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.text-4xl {
    font-family: 'Open Sans', sans-serif;
}

.message {
    overflow: hidden;
  }
      
  .balon1 {
    float: right;
    background: #35cce6;
    border-radius: 10px;
  }
      
  .balon2 {
    float: left;
    background: #f4f7f9;
    border-radius: 10px;
  }

  .rcw-launcher img {
    display : inline ! important;
  }

  .rcw-launcher {
    background-color: #38a169 !important;
  }

  .rcw-header {
    background-color: #38a169 !important;
  }

  .noWrap {flex-wrap:nowrap}

  @media screen and (max-width:1024px) {
    .noWrap {flex-wrap:wrap}
  }

  @media screen and (max-height:769px) {
    .media760query {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .label-for-pack-item::after {
    content: '';
    margin-left:auto;
    text-align: center;
    height:25px;
    width: 25px;
    border: 3px solid #10B981;
    border-radius: 100px;
    transition: background-color .2s;
    position:relative;
  }

  .input-for-pack-item + .label-for-pack-item::after {
    content: '✓';
    color: #10B981;
    background-color: #fff;
  }

  .input-uncheck-pack-item + .label-for-pack-item::after {
    content: '';
    margin-left:auto;
    text-align: center;
    height:25px;
    width: 25px;
    border: 3px solid #10B981;
    border-radius: 100px;
    transition: background-color .2s;
  }

  .label-for-pack-item:hover::after {
    z-index: 20;
    content: '✓';
    color: #10B981;
    background-color: #fff;
  }

  .label-for-pack-item-mobile::after {
    content: '';
    margin-left:auto;
    text-align: center;
    height:25px;
    width: 25px;
    border: 3px solid #10B981;
    border-radius: 100px;
    transition: background-color .2s;
    position:relative;
  }

  .input-for-pack-item + .label-for-pack-item-mobile::after {
    content: '✓';
    color: #10B981;
    background-color: #fff;
  }

  .input-uncheck-pack-item + .label-for-pack-item-mobile::after {
    content: '';
    margin-left:auto;
    text-align: center;
    height:25px;
    width: 25px;
    border: 3px solid #10B981;
    border-radius: 100px;
    transition: background-color .2s;
  }